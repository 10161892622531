// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/Career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/NewsIndex.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-our-team-js": () => import("./../../../src/templates/OurTeam.js" /* webpackChunkName: "component---src-templates-our-team-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/ProjectIndex.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-service-index-js": () => import("./../../../src/templates/ServiceIndex.js" /* webpackChunkName: "component---src-templates-service-index-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/SingleNews.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-single-position-js": () => import("./../../../src/templates/SinglePosition.js" /* webpackChunkName: "component---src-templates-single-position-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/SingleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-team-js": () => import("./../../../src/templates/SingleTeam.js" /* webpackChunkName: "component---src-templates-single-team-js" */),
  "component---src-templates-success-page-js": () => import("./../../../src/templates/SuccessPage.js" /* webpackChunkName: "component---src-templates-success-page-js" */)
}

